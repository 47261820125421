<template>
    <div>
        <h3>{{$t('Views.Shop.ZugangsCode.Headlines[0]')}}</h3>
        <p>{{$t('Views.Shop.ZugangsCode.Descriptions[0]')}}</p>

        <label for="payPalId">{{$t('Views.Shop.ZugangsCode.Labels[0]')}}</label>
        <input type="text" name="payPalId" id="payPalId" v-model="payPalId">
        <br>
        <button @click.prevent="getCode">{{$t('Views.Shop.ZugangsCode.Buttons[0]')}}</button>

        <div v-show="accessCode">
            <p>{{$t('Views.Shop.ZugangsCode.Descriptions[1]')}}: </p>
            <h2>{{accessCode}}</h2>
        </div>
    </div>
</template>
<script setup>
import { access } from "fs";
import { computed, ref } from "vue"

import { useStore } from "vuex";
const store = useStore()
const payPalId = computed(() => store.state.shop.payPalId)
const accessCode = ref(false);
const getCode = async() => {
    accessCode.value =  await fetch(`https://${process.env.VUE_APP_URL}/backend/api/shop/getAccessCode.php`, {method: 'POST', body: JSON.stringify({paypalId: payPalId.value})}).then((res) => res.json())
    store.commit({
        type: 'updateAccessCode',
        accessCode: accessCode.value
    })
}
</script>
<style scoped>

button {
    background: var(--main-bg-color);
    color: var(--base-white);
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 25px;
}
</style>