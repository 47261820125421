<template>
    <div>
        <h3>{{ $t('Views.Shop.Kontakt.Headlines[0]') }}</h3>
        <p>
            {{ $t('Views.Shop.Kontakt.Descriptions[0]') }}
        </p>
        <form>
            <label for="email">{{ $t('Views.Shop.Kontakt.Labels[0]') }}*</label>
            <input type="email" id="email" name="email" required v-model="email"/>
            <label for="description">{{ $t('Views.Shop.Kontakt.Labels[1]') }}</label>
            <textarea name="description" id="description" cols="30" rows="10" v-model="text"></textarea>
            <button @click.prevent="submit">{{ $t('Views.Shop.Kontakt.Buttons[0]') }}</button>
        </form>
    </div>
</template>
<script setup>
import { ref, computed } from "vue"
import { useVueSweetAlert2 } from "@/lib/useVueSweetAlert2";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore()
const email = computed(() => store.state.shop.email)
const text = ref('');
const $swal = useVueSweetAlert2()
const { t } = useI18n()
const vm = this

const submit = async () => {
    
    //console.log(email.value, text.value)

    await fetch(`https://${process.env.VUE_APP_URL}/backend/api/contact/request.php`, {method: 'POST', body: JSON.stringify({text: text.value, email: email.value})}).then((res) => res.json())

    $swal.fire({
        icon: 'success',
        title: t(`Views.Shop.Kontakt.Alert`),
        showConfirmButton: false,
    })
}


</script>
<style scoped>
.router-view {
    margin-bottom: 25px;
}
form {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 15px;
    margin-left: auto;
    margin-right: auto;
}
button {
    background: var(--main-bg-color);
    color: var(--base-white);
    padding: 10px;
    border: none;
    cursor: pointer;
}
</style>